import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { AppRouter } from './server';

export const TRPC_BASE_URL = '/api/trpc';

export function createClient() {
  return createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: TRPC_BASE_URL,
      }),
    ],
  });
}

// default client
export const rpcClient = createClient();

export const EDGE_TRPC_BASE_URL = '/api/edge/trpc';

export function createEdgeClient() {
  return createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: EDGE_TRPC_BASE_URL,
      }),
    ],
  });
}

// default client
export const edgeRpcClient = createClient();
