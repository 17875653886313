'use client';
import * as React from 'react';
import { rpcClient } from '@dreamplan/trpc/client';
import { UserInformation } from '@dreamplan/types';
import { AuthenticationContextProvider } from './AuthenticationContextProvider';

export function AuthenticationClientWrapper({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: UserInformation & { uid: string };
}) {
  return (
    <AuthenticationContextProvider
      init={
        user
          ? {
              type: 'signed_in',
              data: {
                ...user,
              },
            }
          : { type: 'loading' }
      }
      onGetProfile={async () => {
        const resp = await rpcClient.user.profile.get.query();
        return resp as UserInformation;
      }}
      onEditUser={async (cred: Partial<{ email: string; name: string }>) => {
        await rpcClient.user.profile.updateEmail.mutate({ email: cred.email, name: cred.name });
      }}
    >
      {children}
    </AuthenticationContextProvider>
  );
}
