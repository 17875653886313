'use client';
import { parseLocale } from '@dreamplan/utils';
import { useParams } from 'next/navigation';

export function useLocale() {
  const params = useParams();
  const _locale = (params?.['locale'] as string) || 'da';
  const locale = parseLocale(_locale);

  return locale;
}
