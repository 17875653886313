'use client';
import * as React from 'react';
import { edgeRpcClient } from '@dreamplan/trpc/client';
import { FeatureFlags } from './types';
import { useAuth } from '@dreamplan/shared-ui/client/auth';

/**
 * global cache for feature flags - to prevent request waterfall and redundancy in requests
 */
let GlobalFeatureFlags: Promise<Record<string, unknown>> | null = null;

export async function getRemoteFeatureFlag<T>(name: FeatureFlags): Promise<T | null> {
  try {
    if (GlobalFeatureFlags === null) {
      //@ts-ignore
      GlobalFeatureFlags = edgeRpcClient.services.featureFlags.getAll.query();
    }

    //@ts-ignore
    const response = (await GlobalFeatureFlags)[name];
    return response as T;
  } catch (exception) {
    console.error(exception);
    return null;
  }
}

export function useFeatureFlag<T>(name: FeatureFlags) {
  const { user } = useAuth();
  const [value, setValue] = React.useState<{
    isFeatureFlagEnabled: boolean | null;
    value: T | null;
  }>({ isFeatureFlagEnabled: null, value: null });

  React.useEffect(() => {
    if (user.type !== 'loading') {
      const isUserInternal = user.type === 'signed_in' && user.data.email?.match(/@dreamplan\.io$/);
      getRemoteFeatureFlag<T>(name).then((response) => {
        setValue({
          value: response,
          isFeatureFlagEnabled: isUserInternal ? true : Boolean(response),
        });
      });
    }
  }, [name, user]);

  return value;
}
